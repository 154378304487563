@import '../../../../styles/index.scss';

.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: _var(--global-sm-padding);
  padding-top: _var(--global-lg-padding);
  padding-left: _var(--global-md-padding);
  padding-right: _var(--global-md-padding);
  pointer-events: none;
}

.enter {
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 300ms;
  transform: (translate(0, 0));

  &--from {
    opacity: 0;

    transform: (translate(0, 0.5rem));
  }

  &--to {
    opacity: 1;
    transform: (translate(0, 0));
  }
}
.leave {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-property: transform, opacity;
  transition-duration: 100ms;
  transform: (translate(0, 0));

  &--from {
    opacity: 1;
  }

  &--to {
    opacity: 0;
  }
}

.container {
  display: flex;
  flex: 1 1 0%;
}

.toast {
  border-radius: rem-calc(8);
  max-width: rem-calc(380);
  width: 100%;
  pointer-events: auto;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);

  padding: _var(--global-md-padding);
  display: flex;
  align-items: center;
  background-color: $white;
}

.iconContainer {
  flex-shrink: 0;

  &__icon {
    width: rem-calc(24);
    height: rem-calc(24);
    color: $blue-info;

    &--warning {
      color: $warning-color;
    }

    &--error {
      color: $red-primary;
    }

    &--success {
      color: $green-primary;
    }
  }
}

.content {
  padding-top: rem-calc(2);
  flex: 1 1 0%;
  margin-left: rem-calc(12);

  &__title {
    margin-bottom: 0;
    color: $gray-80;
    font-size: $small-font-size;
  }

  &__description {
    margin-bottom: 0;
    margin-top: rem-calc(4);
    color: $gray-60;
    font-size: $small-font-size;
  }
}
