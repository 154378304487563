.-m-auto {
  margin: auto !important;
}

.-mt {
  margin-top: _var(--global-padding) !important;
}

.-mt-large {
  margin-top: _var(--global-lg-padding) !important;
}

.-mt-xxlarge {
  margin-top: _var(--global-xxlg-padding) !important;
}

.-mb-xlarge {
  margin-bottom: _var(--global-xlg-padding) !important;
}

.-mb-xxlarge {
  margin-bottom: _var(--global-xxlg-padding) !important;
}

.-mb-xxxlarge {
  margin-bottom: _var(--global-xxxlg-padding) !important;
}

.-mb {
  margin-bottom: _var(--global-padding) !important;
}

.-mb-none {
  margin-bottom: 0 !important;
}

.-mb-small {
  margin-bottom: _var(--global-sm-padding) !important;
}
.-mb-xsmall {
  margin-bottom: _var(--global-xsm-padding) !important;
}

.-mb-large {
  margin-bottom: _var(--global-lg-padding) !important;
}

.-mr-none {
  margin-right: 0 !important;
}

.-mr {
  margin-right: _var(--global-padding) !important;
}

.-mr-small {
  margin-right: _var(--global-sm-padding) !important;
}

.-mr-medium {
  margin-right: _var(--global-md-padding) !important;
}

.-mr-xlarge {
  margin-right: _var(--global-xlg-padding) !important;
}

.-ml-small {
  margin-left: _var(--global-sm-padding) !important;
}

.-ml-xlarge {
  margin-left: _var(--global-xlg-padding) !important;
}
