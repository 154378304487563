@use 'sass:math';

// (Sync w/ ./globals.scss)
// Note: :host is used to target the root element within a shadow DOM
:root,
:host {
  --#{$product-namespace}-rem-base: 16;
  --#{$product-namespace}-radius-base: 4;
  --#{$product-namespace}-scrollbar-width: thin;

  // Common Colors
  --#{$product-namespace}-white: #ffffff;
  --#{$product-namespace}-ghost-white: #f5f5f5;
  --#{$product-namespace}-gray-50: #f7f8fb;
  --#{$product-namespace}-gray-100: #ebecf2;
  --#{$product-namespace}-gray-200: #d8dae5;
  --#{$product-namespace}-gray-300: #a2abb8;
  --#{$product-namespace}-gray-400: #848e9e;
  --#{$product-namespace}-gray-500: #828699;
  --#{$product-namespace}-gray-600: #4c5563;
  --#{$product-namespace}-gray-700: #515466;
  --#{$product-namespace}-gray-800: #22262e;
  --#{$product-namespace}-gray-900: #151a1f;
  --#{$product-namespace}-gray-950: #0e1217;
  --#{$product-namespace}-gray-1000: #12131a;
  --#{$product-namespace}-primary-color: #3259e8;
  --#{$product-namespace}-primary-foreground-color: #ffffff;
  --#{$product-namespace}-primary-accent-color: #3881f3;
  --#{$product-namespace}-secondary-font-color: #626d7c;
  --#{$product-namespace}-secondary-color: #e2e5eb;
  --#{$product-namespace}-secondary-color-muted: #f7f8fb;
  --#{$product-namespace}-secondary-foreground-color: #000000;
  --#{$product-namespace}-secondary-color-hover: #c3c9d1;
  --#{$product-namespace}-error-primary-color: #ff4e4e;
  --#{$product-namespace}-error-secondary-color: #ffd9d9;
  --#{$product-namespace}-border-color: #c3c9d1;
  --#{$product-namespace}-border-color-muted: #e2e5eb;
  --#{$product-namespace}-border-color-hover: #a2abb8;
  --#{$product-namespace}-border-color-focus: #3259e8;
  --#{$product-namespace}-body-background-color: #ffffff;
  --#{$product-namespace}-body-font-color: #000000;
  --#{$product-namespace}-muted-font-color: #a2abb8;
  --#{$product-namespace}-heading-font-color: #000000;
  --#{$product-namespace}-scrollbar-color: #151a1f;

  // Sections
  --#{$product-namespace}-summary-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.12);

  // Components
  --#{$product-namespace}-dropdown-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  --#{$product-namespace}-accreditation-color: #828699;
  --#{$product-namespace}-accreditation-accent-color: #515466;

  // Padding
  --#{$product-namespace}-global-xsm-padding: #{math.div($global-padding, 4)}; // 5px
  --#{$product-namespace}-global-sm-padding: #{math.div($global-padding, 2)}; // 10px
  --#{$product-namespace}-global-md-padding: #{$global-padding * 0.75}; // 15px
  --#{$product-namespace}-global-padding: #{$global-padding}; // 20px
  --#{$product-namespace}-global-lg-padding: #{$global-padding * 1.25}; // 25px
  --#{$product-namespace}-global-xlg-padding: #{$global-padding * 1.5}; // 30px
  --#{$product-namespace}-global-xxlg-padding: #{$global-padding * 2}; // 40px
  --#{$product-namespace}-global-xxxlg-padding: #{$global-padding * 2.5}; // 50px
  --#{$product-namespace}-global-xxxxlg-padding: #{$global-padding * 3}; // 60px
  --#{$product-namespace}-global-xxxxxlg-padding: #{$global-padding * 3.5}; // 70px

  // Padding
  --#{$product-namespace}-padding-0_5: #{math.div($rem-base, 8)}; // 2px
  --#{$product-namespace}-padding-1: #{math.div($rem-base, 4)}; // 4px
  --#{$product-namespace}-padding-1_5: #{math.div($rem-base, 2.666666)}; // 6px
  --#{$product-namespace}-padding-2: #{math.div($rem-base, 2)}; // 8px
  --#{$product-namespace}-padding-2_5: #{math.div($rem-base, 1.6)}; // 10px
  --#{$product-namespace}-padding-3: #{math.div($rem-base, 1.333333)}; // 12px
  --#{$product-namespace}-padding-4: #{$rem-base}; // 16px
  --#{$product-namespace}-padding-5: #{$rem-base * 1.25}; // 20px
  --#{$product-namespace}-padding-6: #{$rem-base * 1.5}; // 24px
  --#{$product-namespace}-padding-7: #{$rem-base * 1.75}; // 28px
  --#{$product-namespace}-padding-8: #{$rem-base * 2}; // 32px
  --#{$product-namespace}-padding-9: #{$rem-base * 2.25}; // 36px
  --#{$product-namespace}-padding-10: #{$rem-base * 2.5}; // 40px
  --#{$product-namespace}-padding-12: #{$rem-base * 3}; // 48px
  --#{$product-namespace}-padding-15: #{$rem-base * 3.75}; // 60px
  --#{$product-namespace}-padding-16: #{$rem-base * 4}; // 64px
  --#{$product-namespace}-padding-17_5: #{$rem-base * 4.375}; // 70px
  --#{$product-namespace}-padding-20: #{$rem-base * 5}; // 80px

  // Border Radius
  --#{$product-namespace}-rounded-sm: #{math.div($radius-base, 2)}; // 2px
  --#{$product-namespace}-rounded: #{$radius-base}; // 4px
  --#{$product-namespace}-rounded-md: #{$radius-base * 1.5}; // 6px
  --#{$product-namespace}-rounded-lg: #{$radius-base * 2}; // 8px
  --#{$product-namespace}-rounded-xl: #{$radius-base * 3}; // 12px
  --#{$product-namespace}-rounded-2xl: #{$radius-base * 4}; // 16px

  // Typography
  --#{$product-namespace}-body-font-family: Manrope, sans-serif;
  --#{$product-namespace}-body-font-weight: 500;
  --#{$product-namespace}-font-semibold: 600;
  --#{$product-namespace}-font-bold: 700;
  --#{$product-namespace}-heading-letter-spacing: 0;
  --#{$product-namespace}-body-font-size: #{$rem-base}; // 16px
  --#{$product-namespace}-body-line-height: #{$rem-base * 1.5}; // 24px
  --#{$product-namespace}-small-font-size: #{math.div($rem-base, 1.142857)}; // 14px
  --#{$product-namespace}-small-line-height: #{$rem-base * 1.25}; // 20px
  --#{$product-namespace}-xsmall-font-size: #{math.div($rem-base, 1.333333)}; // 12px
  --#{$product-namespace}-xsmall-line-height: #{$rem-base}; // 16px
  --#{$product-namespace}-xxsmall-font-size: #{math.div($rem-base, 1.454545)}; // 11px
  --#{$product-namespace}-xxsmall-line-height: #{$rem-base * 1.1}; // 17.6px
  --#{$product-namespace}-font-size-lg: #{$rem-base * 1.125}; // 18px
  --#{$product-namespace}-line-height-lg: #{$rem-base * 1.75}; // 28px

  --#{$product-namespace}-font-size-xl: #{$rem-base * 1.25}; // 20px
  --#{$product-namespace}-line-height-xl: #{$rem-base * 1.75}; // 28px

  --#{$product-namespace}-font-size-xl-2: #{$rem-base * 1.5}; // 24px
  --#{$product-namespace}-line-height-xl-2: #{$rem-base * 2}; // 32px

  --#{$product-namespace}-font-size-xl-3_5: #{$rem-base * 2}; // 32px
  --#{$product-namespace}-line-height-xl-3_5: #{$rem-base * 2.5}; // 40px

  --#{$product-namespace}-font-size-xl-4: #{$rem-base * 2.25}; // 36px
  --#{$product-namespace}-line-height-xl-4: #{$rem-base * 2.5}; // 40px

  --#{$product-namespace}-font-size-xl-4_5: #{$rem-base * 2.5}; // 40px
  --#{$product-namespace}-line-height-xl-4_5: #{$rem-base * 3.125}; // 50px
}
