.-ov-auto {
  overflow: auto !important;
}

.-ov-visible {
  overflow: visible !important;
}

.-ov-hidden {
  overflow: hidden !important;
}

.-pos-relative {
  position: relative !important;
}

.-cu-notAllowed {
  cursor: not-allowed !important;
}

.-cu-pointer {
  cursor: pointer !important;
}

.-va-top {
  vertical-align: top !important;
}

.-d-none {
  display: none !important;
}
