.-l-fullWidth {
  display: block;
  width: 100% !important;
}

.-l-fullHeight {
  height: 100% !important;
}

.-l-publicPrimaryContainer {
  min-height: calc(100vh - 170px) !important;
  padding-bottom: _var(--global-padding);

  > * {
    max-width: rem-calc(448);
    margin-left: auto;
    margin-right: auto;
  }
}

.-l-simpleCenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

.-l-invisible {
  position: absolute !important;
  visibility: hidden !important;
  overflow: hidden !important;
  opacity: 0 !important;
  height: 0;
  width: 0;
  z-index: -10;
}

.-d-inline-block {
  display: inline-block !important;
}

.-l-height-auto {
  height: auto !important;
}
