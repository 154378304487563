html,
body,
[data-embed='body'] {
  width: 100%;
  height: 100%;
}

body {
  overflow: hidden;
}
