h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: _var(
    --heading-font-family,
    _var(--body-font-family, $header-font-family)
  );
  font-weight: $header-font-weight;
  font-style: $header-font-style;
  color: _var(--heading-font-color, $header-font-color);
  text-rendering: $header-text-rendering;

  small {
    font-size: $small-font-size;
    line-height: 0;
  }
}

h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
}

h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
}

h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}

h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}
