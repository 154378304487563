div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

// /* Default Link Styles */
a {
  font-family: $font-family;
  text-decoration: none;
  line-height: inherit;

  img {
    border: none;
  }
}

// /* Default paragraph styles */
p {
  font-family: $font-family;
  font-weight: $paragraph-font-weight;
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  margin-bottom: $paragraph-margin-bottom;
  text-rendering: $paragraph-text-rendering;

  & aside {
    font-size: $paragraph-aside-font-size;
    line-height: $paragraph-aside-line-height;
    font-style: $paragraph-aside-font-style;
  }
}

// /* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: $font-weight-bold;
  line-height: inherit;
}

small {
  font-size: $small-font-size;
  color: $small-font-color;
  line-height: inherit;
}
