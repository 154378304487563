.-bgc-bluePrimary {
  background-color: $blue-primary !important;
}

.-bgc-white {
  background-color: $white !important;
}

.-bgc-purple {
  background-color: $purple-color !important;
}

.-bgc-gray100 {
  background-color: $gray-100 !important;
}

.-c-bluePrimary {
  color: $blue-primary !important;
}

.-c-gray60 {
  color: $gray-60 !important;
}

.-f-warning {
  fill: $warning-color !important;
}

.-brc-purple {
  border-color: $purple-color !important;
}
