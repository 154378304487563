@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --radius: 0.5rem;
    --accent-foreground: 240 5.9% 10%;
    --accent: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
  }
}
