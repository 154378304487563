// Place Column/Row headers over selections
.reactgrid {
  background-color: $white;

  .rg-pane-top {
    z-index: 9;
  }

  .rg-pane-left {
    z-index: 10;
  }

  .reactgrid-content .rg-pane .rg-cell-focus, // Hide focus sqaure always
  .reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-selected-range {
    opacity: 0;
  }
}

// Reveal focus range while
// user is actively selecting
.selecting > .reactgrid {
  .reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-selected-range {
    opacity: 1;
  }
}
