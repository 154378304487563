.-p-none {
  padding: 0 !important;
}

.-pt-large {
  padding-top: _var(--global-lg-padding) !important;
}

.-pr-large {
  padding-right: _var(--global-lg-padding) !important;
}

.-pb-large {
  padding-bottom: _var(--global-lg-padding) !important;
}

.-pl-large {
  padding-left: _var(--global-lg-padding) !important;
}

.-pt-none {
  padding-top: 0 !important;
}
